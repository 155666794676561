import React, { createContext } from 'react'
import { io } from 'socket.io-client';
import { ENV, PRODUCTION, SERVER_HOST } from './config';
import { CANCEL_PAYMENT, CAPTURE_PAYMENT, CONNECTED_TERMINALS, CREATE_PAYMENT, CREATE_REFUND, CancelPaymentRequest, CapturePaymentRequest, ConnectedTerminalsRequest, CreateAutoPaymentRequest, CreateManualPaymentRequest, CreateRecordPaymentResponse, CreateRecordRefundResponse, DISCONNECT, HandleCancelPaymentResponse, HandleCapturePaymentResponse, HandleConnectedTerminalsResponse, HandleCreatePaymentResponse, HandleCreateRefundResponse, HandleRecordPaymentRequest, HandleRecordRefundRequest, HandleReversePaymentResponse, HandleTerminalNotificationRequest, HandleUpdatePaymentResponse, RECORD_PAYMENT, RECORD_REFUND, REVERSE_PAYMENT, RefundPaymentRequest, ReversePaymentRequest, TERMINAL_NOTIFICATION, UPDATE_PAYMENT, UpdatePaymentRequest } from './pacApi';
import axios from 'axios';
import { WS_CONNECTION_STATUS_CONNECTED, WS_CONNECTION_STATUS_DISCONNECTED, WS_CONNECTION_STATUS_FAILED, WS_CONNECTION_STATUS_OPEN } from '../store/state';
const WebSocketContext = createContext(null);

export { WebSocketContext }

export default ({ children }) => {
  let socket;
  let ws;

  async function connectPacAPI(accountName, apiKey, setWsConnectionStatus, useAGClient, useEnv) {
    const auth = Buffer.from(accountName + ":" + apiKey).toString('base64');
    const response = await axios.get(`${SERVER_HOST}/connect`, {
      headers: {
        accountname: accountName,
        authtoken: auth,
        clientSocketId: socket.id,
        useagclient: useAGClient,
        useenv: useEnv
      }
    })
    if (response.data.status === 200) {
      setWsConnectionStatus(WS_CONNECTION_STATUS_CONNECTED)
      if (ENV !== PRODUCTION) { 
        console.log("Websocket connection to Connect established:", { accountName: accountName, clientSocketId: socket.id, baseUrl: response.data.baseUrl });
      }
    } else {
      setWsConnectionStatus(WS_CONNECTION_STATUS_FAILED)
    }
  }

  function sendCapturePaymentRequest(modals, setModals, baseAmount, paymentId) {
    socket.emit("fromPOS", CapturePaymentRequest(modals, setModals, baseAmount, paymentId));
  }

  function sendCancelPaymentRequest(modals, setModals, paymentId, terminalId) {
    socket.emit("fromPOS", CancelPaymentRequest(modals, setModals, paymentId, terminalId));
  }

  function sendConnectedTerminalsRequest() {
    socket.emit("fromPOS", ConnectedTerminalsRequest());
  }

  function sendCreateManualPaymentRequest(modals, setModals, baseAmount, terminalId, receiptItems, tabId) {
    socket.emit("fromPOS", CreateManualPaymentRequest(modals, setModals, baseAmount, terminalId, receiptItems, tabId));
  }

  function sendCreateAutoPaymentRequest(modals, setModals, baseAmount, terminalId, receiptItems) {
    socket.emit("fromPOS", CreateAutoPaymentRequest(modals, setModals, baseAmount, terminalId, receiptItems));
  }

  function sendRecordPaymentResponse(request) {
    socket.emit("fromPOS", CreateRecordPaymentResponse(request));
  }

  function sendRecordRefundResponse(request) {
    socket.emit("fromPOS", CreateRecordRefundResponse(request));
  }

  function sendRefundPaymentRequest(modals, setModals, paymentId, terminalId, balance) {
    socket.emit("fromPOS", RefundPaymentRequest(modals, setModals, paymentId, terminalId, balance));
  }

  function sendReversePaymentRequest(modals, setModals, paymentId) {
    socket.emit("fromPOS", ReversePaymentRequest(modals, setModals, paymentId));
  }

  function sendUpdatePaymentRequest(modals, setModals, amount, paymentId) {
    socket.emit("fromPOS", UpdatePaymentRequest(modals, setModals, amount, paymentId));
  }

  function registerWsListeners(modals, setModals, setWsConnectionStatus, setConnectionError) {
    if (!socket) {
      socket = io(SERVER_HOST)
      if (ENV !== PRODUCTION) {
        console.log(`Opening a ws connection to the server: ${SERVER_HOST}`)
      }
    }

    if (socket) {
      socket.on("connect", () => {
        setWsConnectionStatus(WS_CONNECTION_STATUS_OPEN);
      });
    }

    socket.on("fromConnect", (data) => {
      data = JSON.parse(data);
      switch (data.method) {
        default: break;
        case DISCONNECT:
          if (ENV !== PRODUCTION) {
            console.log("Websocket connection to Connect disconnected:", data);
          }
          if (data.reason.includes("invalid URI")) {
            setWsConnectionStatus(WS_CONNECTION_STATUS_FAILED);
          } else {
            setWsConnectionStatus(WS_CONNECTION_STATUS_DISCONNECTED);
            setConnectionError("This EPoS has been disconnected from the PAC API by another user or browser tab. If you still wish to use this EPoS, please ensure you are the only person using this account and reload the page.");
          }
          break;
        case CAPTURE_PAYMENT:
          if (ENV !== PRODUCTION) {
            console.log("Capture payment response triggered")
          }
          HandleCapturePaymentResponse(data, modals, setModals);
          break;
        case CANCEL_PAYMENT:
          if (ENV !== PRODUCTION) {
            console.log("Cancel payment response triggered")
          }
          HandleCancelPaymentResponse(data, modals, setModals);
          break;
        case CONNECTED_TERMINALS:
          if (ENV !== PRODUCTION) {
            console.log("Connected terminals response triggered")
          }
          HandleConnectedTerminalsResponse(data, modals, setModals);
          break;
        case CREATE_PAYMENT:
          if (ENV !== PRODUCTION) {
            console.log("Create Payment response triggered")
          }
          HandleCreatePaymentResponse(data, modals, setModals);
          break;
        case CREATE_REFUND:
          if (ENV !== PRODUCTION) {
            console.log("Refund Payment response triggered")
          }
          HandleCreateRefundResponse(data, modals, setModals);
          break;
        case RECORD_REFUND:
          if (ENV !== PRODUCTION) {
            console.log("Record Refund request triggered")
          }
          HandleRecordRefundRequest(data, modals, setModals, sendRecordRefundResponse);
          break;
        case RECORD_PAYMENT:
          if (ENV !== PRODUCTION) {
            console.log("Record Payment request triggered")
          }
          HandleRecordPaymentRequest(data, modals, setModals, sendRecordPaymentResponse);
          break;
        case REVERSE_PAYMENT:
          if (ENV !== PRODUCTION) {
            console.log("Reverse Payment response triggered")
          }
          HandleReversePaymentResponse(data, modals, setModals);
          break;
        case TERMINAL_NOTIFICATION:
          if (ENV !== PRODUCTION) {
            console.log("Terminal notification request triggered")
          }
          HandleTerminalNotificationRequest(data, modals, setModals);
          break;
        case UPDATE_PAYMENT:
          if (ENV !== PRODUCTION) {
            console.log("Update Payment response triggered")
          }
          HandleUpdatePaymentResponse(data, modals, setModals);
          break;
      }
    }, [])
  }

  ws = {
    socket: socket,
    registerWsListeners,
    connectPacAPI,
    sendCapturePaymentRequest,
    sendCancelPaymentRequest,
    sendConnectedTerminalsRequest,
    sendCreateManualPaymentRequest,
    sendCreateAutoPaymentRequest,
    sendRefundPaymentRequest,
    sendReversePaymentRequest,
    sendUpdatePaymentRequest,
  }

  return (
    <WebSocketContext.Provider value={ws}>
      {children}
    </WebSocketContext.Provider>
  )
}