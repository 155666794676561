import { v4 as uuidv4 } from 'uuid';

export const TABLE_STATUS_NOT_IN_USE = "TABLE_STATUS_NOT_IN_USE";
export const TABLE_STATUS_PENDING_AVAILABLE = "TABLE_STATUS_PENDING_AVAILABLE";
export const TABLE_STATUS_AVAILABLE = "TABLE_STATUS_AVAILABLE";
export const TABLE_STATUS_OCCUPIED = "TABLE_STATUS_OCCUPIED";


export const WS_CONNECTION_STATUS_CLOSED = "WS_CONNECTION_STATUS_CLOSED";
export const WS_CONNECTION_STATUS_FAILED = "WS_CONNECTION_STATUS_FAILED";
export const WS_CONNECTION_STATUS_OPEN = "WS_CONNECTION_STATUS_OPEN";
export const WS_CONNECTION_STATUS_CONNECTED = "WS_CONNECTION_STATUS_CONNECTED";
export const WS_CONNECTION_STATUS_DISCONNECTED = "WS_CONNECTION_STATUS_DISCONNECTED";

export const initialState = {
  wsConnectionStatus: WS_CONNECTION_STATUS_CLOSED,
  accountName: "demo0002",
  apiKey: "Payment1",
  useAgClient: "false",
  useEnv: "stag",
  connectionError: "",
  completedSessions: [],
  modals: {
    transactionsView: {
      currentTxId: 0,
      isTransactionSelected: false,
      isVisible: true,
      transactions: [],
      unconfirmedTransactions: []
    },
    tabsView: {
      isTabSelected: false,
      currentTabId: 0,
      isVisible: false,
      tabs: [],
      unconfirmedTabs: []
    },
    pdqView: {
      isVisible: false,
      for: null,
      pdqs: [
      ]
    }, 
    captureView: {
      balance: 0,
      balanceToOmit: 0,
      balanceToCapture: 0,
      currentSelectedItem: {index: -1},
      isVisible: false,
      initialItems: [],
      uncapturedItems: [],
      itemsToCapture: []
    }
  },
  receiptItems: {
    currentSelectedTab: {
      index: -1
    },
    currentSelectedTransaction: {
      index: -1
    },
    currentItem: {
      index: -1
    },
    isVisible: true,
    balance: 0,
    amountHeld: 0,
    amountCaptured: 0,
    items: [],
    receiptLines: {},
    receipt: null
  },
  posItems: {
    isActive: true,
    isVisible: true,
    items: [
      { key: 0, name: "coffee", price: 325 },
      { key: 1, name: "tea", price: 300 },
      { key: 2, name: "water", price: 100 },
      { key: 3, name: "beer", price: 550 },
      { key: 4, name: "spirit", price: 2450 },
      { key: 5, name: "wine", price: 1650 },
      { key: 6, name: "burger", price: 600 },
      { key: 7, name: "chicken", price: 500 },
      { key: 8, name: "fish", price: 840 },
      { key: 9, name: "dessert", price: 725 }, 
      { key: 10, name: "pizza", price: 875 },
      { key: 11, name: "soda", price: 150 },
    ]
  }
}

export function setNewUUID() {
  return uuidv4();
}