import React from 'react';
import { PAYMENT_STATUS_CANCELLED, PAYMENT_STATUS_CAPTURED, PAYMENT_STATUS_CLOSED, PAYMENT_STATUS_COMPLETED, PAYMENT_STATUS_NOT_AUTHORIZED, PAYMENT_STATUS_NOT_REFUNDED, PAYMENT_STATUS_PRE_AUTHORIZED, PAYMENT_STATUS_REFUNDED } from '../../websocket/pacApi';

function Title(props) {

  const { modals, receiptItems } = props;

  const isTabSelected = modals.tabsView.isTabSelected;
  const isTransactionSelected = modals.transactionsView.isTransactionSelected;
  const currentSelectedTabIndex = receiptItems.currentSelectedTab.index;
  const currentSelectedTransactionIndex = receiptItems.currentSelectedTransaction.index;
  const tabs = modals.tabsView.tabs;
  const transactions = modals.transactionsView.transactions;

  function CurrentStatusIsAStableStatus(status) {
    return [
      PAYMENT_STATUS_PRE_AUTHORIZED,
      PAYMENT_STATUS_NOT_AUTHORIZED,
      PAYMENT_STATUS_CAPTURED,
      PAYMENT_STATUS_CANCELLED,
      PAYMENT_STATUS_CLOSED,
      PAYMENT_STATUS_REFUNDED,
      PAYMENT_STATUS_NOT_REFUNDED,
      PAYMENT_STATUS_COMPLETED
    ].includes(status)
  }

  return (
    <div className="row status-row">
      <div id="status" className="status-text col-xs-12">
        {isTabSelected === true && tabs[currentSelectedTabIndex].status === PAYMENT_STATUS_PRE_AUTHORIZED &&
          <>Modify Tab: {"#" + tabs[currentSelectedTabIndex].tabId}</>
        }
        {isTabSelected === true && tabs[currentSelectedTabIndex].status === PAYMENT_STATUS_CAPTURED &&
          <>Modify Tab: {"#" + tabs[currentSelectedTabIndex].tabId} (Tab Captured)</>
        }
        {isTabSelected === true && tabs[currentSelectedTabIndex].status === PAYMENT_STATUS_CLOSED &&
          <>Modify Tab: {"#" + tabs[currentSelectedTabIndex].tabId} (Tab Closed)</>
        }
        {isTabSelected === true && tabs[currentSelectedTabIndex].status === PAYMENT_STATUS_NOT_AUTHORIZED &&
          <>Modify Tab: {"#" + tabs[currentSelectedTabIndex].tabId} (Tab Not Authorized)</>
        }
        {isTabSelected === true && tabs[currentSelectedTabIndex].status === PAYMENT_STATUS_CANCELLED &&
          <>Modify Tab: {"#" + tabs[currentSelectedTabIndex].tabId} (Tab Cancelled)</>
        }
        {isTabSelected === true && !CurrentStatusIsAStableStatus(tabs[currentSelectedTabIndex].status) &&
          <>Modify Tab: {"#" + tabs[currentSelectedTabIndex].tabId} (Tab Awaiting Authorization)</>
        }
        {isTabSelected === false && isTransactionSelected === false &&
          <>Select Items</>
        }
        {isTransactionSelected === true && !CurrentStatusIsAStableStatus(transactions[currentSelectedTransactionIndex].status) &&
          <>Transaction {"#" + transactions[currentSelectedTransactionIndex].txId} (Transaction Awaiting Authorization)</>
        }
        {isTransactionSelected === true && transactions[currentSelectedTransactionIndex].status === PAYMENT_STATUS_COMPLETED &&
          <>Transaction {"#" + transactions[currentSelectedTransactionIndex].txId} (Transaction Completed)</>
        }
        {isTransactionSelected === true && transactions[currentSelectedTransactionIndex].status === PAYMENT_STATUS_REFUNDED &&
          <>Transaction {"#" + transactions[currentSelectedTransactionIndex].txId} (Transaction Refunded)</>
        }
        {isTransactionSelected === true && transactions[currentSelectedTransactionIndex].status === PAYMENT_STATUS_NOT_REFUNDED &&
          <>Transaction {"#" + transactions[currentSelectedTransactionIndex].txId} (Transaction Not Refunded)</>
        }
        {isTransactionSelected === true && transactions[currentSelectedTransactionIndex].status === PAYMENT_STATUS_NOT_AUTHORIZED &&
          <>Transaction {"#" + transactions[currentSelectedTransactionIndex].txId} (Transaction Not Authorized)</>
        }
        {isTransactionSelected === true && transactions[currentSelectedTransactionIndex].status === PAYMENT_STATUS_CANCELLED &&
          <>Transaction {"#" + transactions[currentSelectedTransactionIndex].txId} (Transaction Cancelled)</>
        }
      </div>
    </div>
  );
}

export { Title }