import { setNewUUID } from "../store/state";

export const CANCEL_PAYMENT = "CancelPayment";
export const CANCEL_REFUND = "CancelRefund";
export const CAPTURE_PAYMENT = "CapturePayment";
export const CONNECTED_TERMINALS = "ConnectedTerminals";
export const CREATE_PAYMENT = "CreatePayment";
export const CREATE_REFUND = "CreateRefund";
export const RECORD_PAYMENT = "RecordPayment";
export const RECORD_REFUND = "RecordRefund";
export const REVERSE_PAYMENT = "ReversePayment";
export const SIGNATURE_VERIFICATION = "SignatureVerification";
export const TERMINAL_DETAILS = "TerminalDetails";
export const TERMINAL_NOTIFICATION = "terminalNotification";
export const UPDATE_PAYMENT = "UpdatePayment";
export const DISCONNECT = "Disconnect";

export const CAPTURE_MODE_AUTO = "AUTO";
export const CAPTURE_MODE_MANUAL = "MANUAL";

export const PAYMENT_STATUS_AWAITING_AUTH = "AWAITING_AUTH";
export const PAYMENT_STATUS_AWAITING_PRE_AUTH = "AWAITING_PRE_AUTH";
export const PAYMENT_STATUS_CAPTURED = "CAPTURED";
export const PAYMENT_STATUS_CANCELLED = "CANCELLED";
export const PAYMENT_STATUS_CLOSED = "CLOSED";
export const PAYMENT_STATUS_COMPLETED = "COMPLETED";
export const PAYMENT_STATUS_NOT_AUTHORIZED = "NOT_AUTHORIZED";
export const PAYMENT_STATUS_OPEN = "OPEN";
export const PAYMENT_STATUS_PRE_AUTHORIZED = "PRE_AUTHORIZED";
export const PAYMENT_STATUS_REFUNDED = "REFUNDED";
export const PAYMENT_STATUS_NOT_REFUNDED = "NOT_REFUNDED";


export const TERMINAL_STATUS_AVAILABLE = "AVAILABLE";
export const TERMINAL_STATUS_BUSY = "BUSY";
export const TERMINAL_CURRENCY_GBP = "GBP";

export const PAYMENT_METHOD_CARD_PRESENT = "PAYMENT_METHOD_CARD_PRESENT";
export const PAYMENT_METHOD_REMOTE = "PAYMENT_METHOD_REMOTE";

export const FORMAT_BOLD = "BOLD";
export const FORMAT_DOUBLE_WIDTH = "DOUBLE_WIDTH";
export const FORMAT_DOUBLE_HEIGHT = "DOUBLE_HEIGHT";

let counter = 0;

export function ConnectedTerminalsRequest() {
  return createPacRequest(CONNECTED_TERMINALS, {
    status: TERMINAL_STATUS_AVAILABLE,
    currency: TERMINAL_CURRENCY_GBP
  });
}

export function CreateAutoPaymentRequest(modals, setModals, baseAmount, terminalId, receiptItems) {
  const request = paymentRequest(
    baseAmount,
    CAPTURE_MODE_AUTO,
    {
      captureModeOptionsAuto: {
        cashbackAmount: 0,
        gratuityAmount: 0
      }
    },
    false,
    terminalId
  );
  modals.transactionsView.unconfirmedTransactions.push(
    {
      id: request.id,
      txId: modals.transactionsView.currentTxId,
      pid: request.params.paymentId,
      balance: baseAmount,
      status: PAYMENT_STATUS_AWAITING_AUTH,
      items: receiptItems.items
    });
  setModals({ ...modals });
  return request;
}


export function CreateManualPaymentRequest(modals, setModals, baseAmount, terminalId, receiptItems, tabId) {
  const request = paymentRequest(baseAmount, CAPTURE_MODE_MANUAL, {}, false, terminalId, receiptItems);
  modals.tabsView.unconfirmedTabs.push(
    {
      id: request.id,
      pid: request.params.paymentId,
      tabId: tabId,
      balance: baseAmount,
      amountHeld: baseAmount,
      amountCaptured: 0,
      status: PAYMENT_STATUS_AWAITING_PRE_AUTH,
      items: receiptItems.items
    }
  );
  setModals({ ...modals });
  return request;
}

export function CapturePaymentRequest(modals, setModals, baseAmount, paymentId) {
  modals.tabsView.isTabSelected = false;
  modals.transactionsView.isTransactionSelected = false;
  setModals({ ...modals });
  return createPacRequest(CAPTURE_PAYMENT, {
    baseAmount: baseAmount,
    gratuityAmount: 0,
    paymentId: paymentId
  });
}

export function CancelPaymentRequest(modals, setModals, paymentId, terminalId) {
  modals.tabsView.isTabSelected = false;
  modals.transactionsView.isTransactionSelected = false;

  setModals({ ...modals });
  return createPacRequest(CANCEL_PAYMENT, {
    paymentId: paymentId,
    terminalId: terminalId
  });
}

export function RefundPaymentRequest(modals, setModals, paymentId, terminalId, balance) {
  modals.tabsView.isTabSelected = false;
  modals.transactionsView.isTransactionSelected = false;
  setModals({ ...modals });
  return createPacRequest(CREATE_REFUND, {
    paymentId: paymentId,
    terminalId: terminalId,
    cardHolderNotPresent: false,
    currency: TERMINAL_CURRENCY_GBP,
    amount: balance
  });
}

export function ReversePaymentRequest(modals, setModals, paymentId) {
  modals.tabsView.isTabSelected = false;
  modals.transactionsView.isTransactionSelected = false;
  setModals({ ...modals });
  return createPacRequest(REVERSE_PAYMENT, {
    paymentId: paymentId
  });
}

export function UpdatePaymentRequest(modals, setModals, amount, paymentId) {
  modals.tabsView.isTabSelected = false;
  modals.transactionsView.isTransactionSelected = false;
  setModals({ ...modals });
  return createPacRequest(UPDATE_PAYMENT, {
    amountTo: amount,
    paymentId: paymentId
  });
}

function paymentRequest(baseAmount, captureMode, captureModeOptions, cardHolderNotPresent, terminalId) {
  return createPacRequest(CREATE_PAYMENT, {
    baseAmount: baseAmount,
    captureMode: captureMode,
    captureModeOptions: captureModeOptions,
    cardHolderNotPresent: cardHolderNotPresent,
    currency: TERMINAL_CURRENCY_GBP,
    paymentId: setNewUUID(),
    terminalId: terminalId
  });
}

export function HandleCreatePaymentResponse(response, modals, setModals) {
  if (response.result) {
    const unconfirmedTxs = modals.transactionsView.unconfirmedTransactions;
    const unconfirmedTabs = modals.tabsView.unconfirmedTabs;
    const foundTxIndex = unconfirmedTxs.findIndex(tx => tx.id === response.id);
    const foundTabIndex = unconfirmedTabs.findIndex(tab => tab.id === response.id);

    if (foundTxIndex !== -1) {
      const txToConfirm = unconfirmedTxs.splice(foundTxIndex, 1);
      modals.transactionsView.transactions.push(txToConfirm[0]);
      modals.transactionsView.currentTxId++;
    } else if (foundTabIndex !== -1) {
      const tabToConfirm = unconfirmedTabs.splice(foundTabIndex, 1);
      modals.tabsView.currentTabId++;
      modals.tabsView.tabs.push(tabToConfirm[0]);
    }
    setModals({ ...modals })
  }
}

export function HandleCapturePaymentResponse(response, modals, setModals) {
  if (response.result) {
    const foundTabIndex = modals.tabsView.tabs.findIndex(tab => tab.pid === response.result.paymentId);
    if (foundTabIndex !== -1) {
      modals.tabsView.tabs[foundTabIndex].gratuity = response.result.gratuityCapturedAmount;
      modals.tabsView.tabs[foundTabIndex].amountCaptured = response.result.baseCapturedAmount;
      modals.tabsView.tabs[foundTabIndex].status = PAYMENT_STATUS_CAPTURED;
      setModals({ ...modals });
    }
  }
}

export function HandleCancelPaymentResponse(response, modals, setModals) {
  if (response.result) {
    const foundTabIndex = modals.tabsView.tabs.findIndex(tab => tab.pid === response.result.paymentId);
    const foundTxIndex = modals.transactionsView.transactions.findIndex(tx => tx.pid === response.result.paymentId);
    if (foundTabIndex !== -1) {
      modals.tabsView.tabs[foundTabIndex].status = PAYMENT_STATUS_CANCELLED;
      modals.tabsView.tabs[foundTabIndex].amountHeld = 0;
    } else if (foundTxIndex !== -1) {
      modals.transactionsView.transactions[foundTxIndex].status = PAYMENT_STATUS_CANCELLED;
    }
    setModals({ ...modals });
  }
}

export function HandleCreateRefundResponse(response, modals, setModals) {
  if (response.result) {
    const foundTxIndex = modals.transactionsView.transactions.findIndex(tx => tx.pid === response.result.paymentId);
    if (foundTxIndex !== -1) {
      modals.transactionsView.transactions[foundTxIndex].status = PAYMENT_STATUS_AWAITING_AUTH;
    }
    setModals({ ...modals });
  }
}

export function HandleReversePaymentResponse(response, modals, setModals) {
  if (response.result) {
    const foundTabIndex = modals.tabsView.tabs.findIndex(tab => tab.pid === response.result.paymentId);
    if (foundTabIndex !== -1) {
      modals.tabsView.tabs[foundTabIndex].status = PAYMENT_STATUS_CLOSED;
      modals.tabsView.tabs[foundTabIndex].amountHeld = 0;
    }
    setModals({ ...modals });
  }
}

export function HandleUpdatePaymentResponse(response, modals, setModals) {
  if (response.result) {
    const foundTabIndex = modals.tabsView.tabs.findIndex(tab => tab.pid === response.result.paymentId);
    if (foundTabIndex !== -1) {
      modals.tabsView.tabs[foundTabIndex].amountHeld = response.result.updatedTo;
      modals.tabsView.tabs[foundTabIndex].balance = response.result.updatedTo;
      setModals({ ...modals });
    }
  }
}

export function HandleConnectedTerminalsResponse(response, modals, setModals) {
  modals.pdqView.pdqs = [];
  response.result.forEach(terminal => {
    modals.pdqView.pdqs.push({ tid: terminal.terminalId });
  });
  modals.tabsView.isVisible = false;
  modals.pdqView.isVisible = true;
  setModals({ ...modals });
}

export function HandleTerminalNotificationRequest(response, modals, setModals) {
  if (response.params) {
    const status = response.params.notificationValue;
    const txs = modals.transactionsView.transactions;
    const tabs = modals.tabsView.tabs;
    const foundTxIndex = txs.findIndex(tx => tx.pid === response.params.paymentId);
    const foundTabIndex = tabs.findIndex(tb => tb.pid === response.params.paymentId);

    if (foundTxIndex !== -1) {
      if (![PAYMENT_STATUS_CAPTURED, PAYMENT_STATUS_CLOSED, PAYMENT_STATUS_NOT_AUTHORIZED].includes(txs[foundTxIndex].status)) {
        txs[foundTxIndex].status = status;
        modals.transactionsView.isVisible = true;
      }
    } else if (foundTabIndex !== -1) {
      if (![PAYMENT_STATUS_CAPTURED, PAYMENT_STATUS_CLOSED, PAYMENT_STATUS_NOT_AUTHORIZED].includes(tabs[foundTabIndex].status)) {
        tabs[foundTabIndex].status = status;
        modals.transactionsView.isVisible = false;
      }
    }
    setModals({ ...modals })
  }
}

export function HandleRecordRefundRequest(request, modals, setModals, sendRecordRefundResponse) {
  if (request.params) {
    const txs = modals.transactionsView.transactions;
    const foundTxIndex = txs.findIndex(tx => tx.pid === request.params.paymentId);
    if (request.params.refundSuccessful === true) {
      if (foundTxIndex !== -1) {
        txs[foundTxIndex].status = PAYMENT_STATUS_REFUNDED;
        modals.transactionsView.isVisible = true;
        txs[foundTxIndex].receiptLines = request.params.receiptLines;
      }
    } else {
      if (foundTxIndex !== -1) {
        txs[foundTxIndex].status = PAYMENT_STATUS_NOT_REFUNDED;
        modals.transactionsView.isVisible = true;
      }
    }
    sendRecordRefundResponse(request);
    setModals({ ...modals })
  }
}

export function HandleRecordPaymentRequest(request, modals, setModals, sendRecordPaymentResponse) {
  if (request.params) {
    const txs = modals.transactionsView.transactions;
    const tabs = modals.tabsView.tabs;
    const foundTxIndex = txs.findIndex(tx => tx.pid === request.params.paymentId);
    const foundTabIndex = tabs.findIndex(tb => tb.pid === request.params.paymentId);
    if (request.params.paymentSuccessful === true) {
      if (foundTxIndex !== -1) {
        if (![PAYMENT_STATUS_CAPTURED, PAYMENT_STATUS_CLOSED, PAYMENT_STATUS_NOT_AUTHORIZED].includes(txs[foundTxIndex].status)) {
          txs[foundTxIndex].status = PAYMENT_STATUS_COMPLETED;
          modals.transactionsView.isVisible = true;
        }
        txs[foundTxIndex].receiptLines = request.params.receiptLines;
      } else if (foundTabIndex !== -1) {
        if (![PAYMENT_STATUS_CAPTURED, PAYMENT_STATUS_CLOSED, PAYMENT_STATUS_NOT_AUTHORIZED].includes(tabs[foundTabIndex].status)) {
          const baseHeldAmount = request.params.baseHeldAmount;
          const baseCapturedAmount = request.params.baseCapturedAmount;
          if (baseCapturedAmount < baseHeldAmount) {
            tabs[foundTabIndex].status = PAYMENT_STATUS_PRE_AUTHORIZED;
          } else {
            tabs[foundTabIndex].status = PAYMENT_STATUS_CAPTURED;
          }
          tabs[foundTabIndex].amountHeld = baseHeldAmount;
          tabs[foundTabIndex].amountCaptured = baseCapturedAmount;
          modals.transactionsView.isVisible = false;
        }
        tabs[foundTabIndex].receiptLines = request.params.receiptLines;
      }
    } else {
      if (foundTxIndex !== -1) {
        if (![PAYMENT_STATUS_CAPTURED, PAYMENT_STATUS_CLOSED, PAYMENT_STATUS_NOT_AUTHORIZED].includes(txs[foundTxIndex].status)) {
          txs[foundTxIndex].status = PAYMENT_STATUS_NOT_AUTHORIZED;
          modals.transactionsView.isVisible = true;
        }
      } else if (foundTabIndex !== -1) {
        if (![PAYMENT_STATUS_CAPTURED, PAYMENT_STATUS_CLOSED, PAYMENT_STATUS_NOT_AUTHORIZED].includes(tabs[foundTabIndex].status)) {
          tabs[foundTabIndex].status = PAYMENT_STATUS_NOT_AUTHORIZED;
          modals.transactionsView.isVisible = false;
        }
      }
    }
    sendRecordPaymentResponse(request);
    setModals({ ...modals })
  }
}

export function CreateRecordPaymentResponse(request) {
  return wrapPacResponse(request, {});
}

export function CreateRecordRefundResponse(request) {
  return wrapPacResponse(request, {});
}

function createPacRequest(method, params) {
  const request = {
    id: counter,
    jsonrpc: "2.0",
    method: method
  }

  if (params) {
    request.params = params;
  }
  counter++;
  return request;
}

function wrapPacResponse(request, data) {
  return {
    id: request.id,
    jsonrpc: "2.0",
    result: data
  }
}
