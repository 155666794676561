import React from 'react';
import { PAYMENT_STATUS_PRE_AUTHORIZED } from '../../websocket/pacApi';

function ModalTabsSelection(props) {

  const { modals, setModals, receiptItems, setReceiptItems, ws } = props;

  const tabs = modals.tabsView.tabs;
  const tabsList = modals.tabsView.tabs.filter(tb => tb.status === PAYMENT_STATUS_PRE_AUTHORIZED).map((tab, index) => {
    return (
      <div key={index} onClick={(e) => addToExistingTab(e)} data-id={index} className="col-lg-12 modal-pdq-link__container">
        <div className="modal-pdq-link">{tab.tabId}</div>
      </div>
    )
  });

  function hideModal(e) {
    if (e.target.classList.contains("modal-tabs-selection") || e.target.classList.contains("modal-background")) {
      modals.tabsView.isVisible = false;
      setModals({ ...modals })
    }
  }

  function addToExistingTab(e) {
    const selectedTabIndex = parseInt(e.currentTarget.getAttribute('data-id'));
    updateTabItemsAndBalance(selectedTabIndex);
    modals.tabsView.newTabId = tabs[selectedTabIndex];
    modals.tabsView.isVisible = false;
    receiptItems.items = [];
    receiptItems.currentItem = { index: -1 };
    modals.tabsView.isVisible = false;
    receiptItems.balance = 0;
    receiptItems.amountHeld = 0;
    receiptItems.amountCaptured = 0;
    modals.pdqView.for = "tab";
    setReceiptItems({...receiptItems});
    setModals({...modals})
  }

  function updateTabItemsAndBalance(tabIndex) {
    receiptItems.items.forEach(itemToAdd => {
      const itemExistsInTab = tabs[tabIndex].items.findIndex(item => item.name === itemToAdd.name);
      if (itemExistsInTab === -1) {
        tabs[tabIndex].items.push(itemToAdd);
      } else {
        tabs[tabIndex].items[itemExistsInTab].quantity += 1;
      }
      tabs[tabIndex].balance += itemToAdd.price;
    });
  }

  function addToNewTab() {
    modals.tabsView.newTabId = modals.tabsView.currentTabId;
    ws.sendConnectedTerminalsRequest();
  }

  return (
    <div onClick={(e) => hideModal(e)} className={`modal-background ${modals.tabsView.isVisible === true ? "show" : ""}`}>
      <div className={`modal modal-tabs-selection custom-modal fade bs-example-modal-sm ${modals.tabsView.isVisible === true ? "show" : ""}`} tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h4 className="modal-title">Select Tab</h4>
            </div>
            <div className="modal-body">
              <div className="row text-center" >
                {tabsList.length > 0 && tabsList}
                {tabsList.length === 0 && <div style={{ "padding": "20px 10px" }}>You currently have no pre-authorized tabs. Click the button below to add the items to a new tab.</div>}
              </div>
            </div>
          </div>
          <button
            className={`buttons__button button__reports`}
            type="button"
            onClick={() => addToNewTab()}
          >
            Add to New Tab
          </button>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}

export { ModalTabsSelection }