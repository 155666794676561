import React, { useContext, useEffect, useRef, useState } from 'react';
import './App.css';
import './pos.css';

import retailLogo from '../images/retail-logo.png'
import { Provider, useSelector } from 'react-redux'
import store from '../store/store';
import { PosItemSelection } from './PosItemSelection/PosItemSelectionView';
import WebSocketProvider, { WebSocketContext } from '../websocket/wsClient';
import { Footer } from './Footer/Footer';
import { useQueryParam, StringParam, QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { BrowserRouter } from 'react-router-dom';
import { initialState, WS_CONNECTION_STATUS_CONNECTED, WS_CONNECTION_STATUS_OPEN } from '../store/state';
import { PosReceipt } from './PosReceipt/PosReceiptView';
import { ModalTransactionsOrTabs } from './Modals/TransactionsOrTabsView';
import { PosButtons } from './PosButtons/PosButtons';
import { ModalPDQSelection } from './Modals/PDQSelectionView';
import { ConnectionError } from './ConnectionErrors/ConnectionErrorView';
import { ModalTabsSelection } from './Modals/TabsSelectionView';
import { Title } from './Title/Title';
import { ModalCapture } from './Modals/CaptureView';

function HomeComponent() {
  const [accountName, setAccountName] = useQueryParam('accountName', StringParam);
  const [apiKey, setApiKey] = useQueryParam('apiKey', StringParam);
  const [useAgClient, setUseAgClient] = useQueryParam('realAG', StringParam);
  const [useEnv, setUseEnv] = useQueryParam('env', StringParam);
  const [wsConnectionStatus, setWsConnectionStatus] = useState(store.getState().wsConnectionStatus);
  const [modals, setModals] = useState(store.getState().modals);
  const [receiptItems, setReceiptItems] = useState(store.getState().receiptItems);
  const posItems = useSelector(state => state.posItems);
  const [connectionError, setConnectionError] = useState(store.getState().connectionError);

  const ws = useContext(WebSocketContext);
  const firstRender = useRef(true);

  if (wsConnectionStatus === WS_CONNECTION_STATUS_OPEN && accountName && apiKey && ["stag", "prod", "dev"].includes(useEnv)) {
    ws.connectPacAPI(accountName, apiKey, setWsConnectionStatus, useAgClient, useEnv);
  }

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      ws.registerWsListeners(modals, setModals, setWsConnectionStatus, setConnectionError);

      const url = new URL(window.location.origin);

      if (wsConnectionStatus !== WS_CONNECTION_STATUS_CONNECTED && !(accountName && apiKey)) {
        setAccountName(initialState.accountName)
        setApiKey(initialState.apiKey)
        setUseEnv(initialState.useEnv)
        url.searchParams.set('accountName', initialState.accountName);
        url.searchParams.set('apiKey', initialState.apiKey);
        url.searchParams.set('env', initialState.useEnv);
        window.location.assign(url.search);
      } else if (!["stag", "prod", "dev"].includes(useEnv)) {
        setUseEnv(initialState.useEnv)
        url.searchParams.set('accountName', accountName);
        url.searchParams.set('apiKey', apiKey);
        url.searchParams.set('env', initialState.useEnv);
        window.location.assign(url.search);
      }
    }
  }, [ws, modals, accountName, apiKey, useAgClient, useEnv, setAccountName, setModals, setApiKey, setUseAgClient, setUseEnv, setConnectionError, wsConnectionStatus])

  function clearPreviouslySelectedItems(array, index) {
    array.forEach((item, i) => {
      if (i !== index) {
        item.selected = false;
      }
    })
  }

  return (
    <>
      <ConnectionError wsConnectionStatus={wsConnectionStatus} connectionError={connectionError} />
      <div id="mainContainer" className="container container__customize">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-4">
            <div className="business-brand text-center full-screen-switcher">
              <div className="business-brand__client-logo">
                <img src={retailLogo} alt="Dojo" />
              </div>
              <div className="business-brand__client-name">
                Colin's Retail
              </div>
            </div>
            <ModalTransactionsOrTabs
              displayMode={0}
              modals={modals}
              setModals={setModals}
              receiptItems={receiptItems}
              setReceiptItems={setReceiptItems}
              clearPreviouslySelectedItems={clearPreviouslySelectedItems}
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-8">
            <div className="f5f5f5-bg pd10">
              <Title
                modals={modals}
                receiptItems={receiptItems}
              />
              <div className="row receipt-and-products-container">
                <PosReceipt
                  modals={modals}
                  receiptItems={receiptItems}
                  setReceiptItems={setReceiptItems}
                  clearPreviouslySelectedItems={clearPreviouslySelectedItems}
                />
                <PosItemSelection
                  modals={modals}
                  receiptItems={receiptItems}
                  setReceiptItems={setReceiptItems}
                  posItems={posItems}
                />
              </div>
            </div>
          </div>
        </div>
        <PosButtons
          modals={modals}
          setModals={setModals}
          receiptItems={receiptItems}
          setReceiptItems={setReceiptItems}
          clearPreviouslySelectedItems={clearPreviouslySelectedItems}
          ws={ws}
        />

        <ModalTransactionsOrTabs
          displayMode={1}
          modals={modals}
          setModals={setModals}
          receiptItems={receiptItems}
          setReceiptItems={setReceiptItems}
          clearPreviouslySelectedItems={clearPreviouslySelectedItems}
        />
      </div>

      <ModalTabsSelection
        modals={modals}
        setModals={setModals}
        receiptItems={receiptItems}
        setReceiptItems={setReceiptItems}
        ws={ws}
      />

      <ModalPDQSelection
        modals={modals}
        setModals={setModals}
        receiptItems={receiptItems}
        setReceiptItems={setReceiptItems}
        clearPreviouslySelectedItems={clearPreviouslySelectedItems}
        ws={ws}
      />

      <ModalCapture
        modals={modals}
        setModals={setModals}
        receiptItems={receiptItems}
        setReceiptItems={setReceiptItems}
        clearPreviouslySelectedItems={clearPreviouslySelectedItems}
        ws={ws}
      />

      <Footer />
    </>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <WebSocketProvider>
            <div className="App">
              <HomeComponent />
            </div>
          </WebSocketProvider>
        </QueryParamProvider>
      </Provider>
    </BrowserRouter>
  )
}

export default App;
