import React from 'react';
import { PAYMENT_STATUS_CANCELLED, PAYMENT_STATUS_CAPTURED, PAYMENT_STATUS_CLOSED, PAYMENT_STATUS_COMPLETED, PAYMENT_STATUS_NOT_AUTHORIZED, PAYMENT_STATUS_NOT_REFUNDED, PAYMENT_STATUS_PRE_AUTHORIZED, PAYMENT_STATUS_REFUNDED } from '../../websocket/pacApi';

function PosButtons(props) {

  const { modals, setModals, receiptItems, setReceiptItems, clearPreviouslySelectedItems, ws } = props;
  const tabs = modals.tabsView.tabs;
  const transactions = modals.transactionsView.transactions;
  const isTabSelected = modals.tabsView.isTabSelected;
  const isTransactionSelected = modals.transactionsView.isTransactionSelected;
  const currentItemIndex = receiptItems.currentItem.index;
  const currentTab = tabs[receiptItems.currentSelectedTab.index];
  const currentTransaction = transactions[receiptItems.currentSelectedTransaction.index];

  function removeItem() {
    const itemToDelete = receiptItems.items[receiptItems.currentItem.index];
    receiptItems.balance -= receiptItems.currentItem.price;

    if (itemToDelete.quantity > 1) {
      itemToDelete.quantity -= 1;
    } else {
      receiptItems.items.splice(receiptItems.currentItem.index, 1);
      receiptItems.currentItem = { index: -1 }
    }
    setReceiptItems({ ...receiptItems })
  }

  function clearTabItems() {
    clearPOSItems();
    currentTab.items = receiptItems.items;
    setModals({ ...modals });
  }

  function clearPOSItems() {
    receiptItems.isVisible = true;
    receiptItems.balance = 0;
    receiptItems.amountHeld = 0;
    receiptItems.amountCaptured = 0;
    receiptItems.items = [];
    receiptItems.receipt = null;
    setReceiptItems({ ...receiptItems })
  }

  function addToTab() {
    modals.pdqView.for = "addToTab";
    modals.tabsView.isVisible = true;
    setModals({ ...modals });
  }

  function cardPayment() {
    modals.pdqView.for = "cardPayment";
    setModals({ ...modals });
    ws.sendConnectedTerminalsRequest();
  }

  function capturePayment() {
    modals.captureView.balance = receiptItems.balance;
    modals.captureView.balanceToCapture = receiptItems.balance;
    modals.captureView.uncapturedItems = [];
    modals.captureView.itemsToCapture = deepCopy(receiptItems).items;
    modals.captureView.initialItems = deepCopy(receiptItems).items;
    modals.captureView.isVisible = true;
    setModals({ ...modals });
  }

  function deepCopy(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  function updatePayment() {
    clearPreviouslySelectedItems(tabs, -1)
    ws.sendUpdatePaymentRequest(modals, setModals, receiptItems.balance, currentTab.pid);
    clearPOSItems();
  }

  function reversePayment() {
    clearPreviouslySelectedItems(tabs, -1);
    ws.sendReversePaymentRequest(modals, setModals, currentTab.pid);
    clearPOSItems();
  }

  function refundPayment() {
    modals.pdqView.for = "refundPayment";
    setModals({ ...modals });
    ws.sendConnectedTerminalsRequest();
  }

  function cancelPayment() {
    modals.pdqView.for = "cancelPayment";
    setModals({ ...modals });
    ws.sendConnectedTerminalsRequest();
  }

  function showReceipt() {
    receiptItems.receiptLines = currentTransaction.receiptLines;
    receiptItems.isVisible = !receiptItems.isVisible;
    setReceiptItems({ ...receiptItems })
  }

  function ReceiptHasNoItems() {
    return receiptItems.items.length === 0;
  }

  function ReceiptItemIsSelected() {
    return currentItemIndex !== -1;
  }

  function BothReceiptOrTabIsNotSeleceted() {
    return receiptItems.currentSelectedTab.index === -1 && receiptItems.currentSelectedTransaction.index === -1;
  }

  function TabIsNotPreAuthorized() {
    return currentTab.status !== PAYMENT_STATUS_PRE_AUTHORIZED;
  }

  function TabAmountIsHeldAmount() {
    return receiptItems.balance === receiptItems.amountHeld;
  }

  function TabHasReachedTerminalStatus() {
    return [PAYMENT_STATUS_CAPTURED, PAYMENT_STATUS_CLOSED, PAYMENT_STATUS_NOT_AUTHORIZED, PAYMENT_STATUS_CANCELLED].includes(currentTab.status);
  }

  function TransactionHasReachedTerminalStatus() {
    return [PAYMENT_STATUS_COMPLETED, PAYMENT_STATUS_REFUNDED, PAYMENT_STATUS_NOT_AUTHORIZED, PAYMENT_STATUS_CANCELLED].includes(currentTransaction.status);
  }

  function TransactionHasNotReachedRefundableState() {
    return ![PAYMENT_STATUS_COMPLETED, PAYMENT_STATUS_NOT_REFUNDED].includes(currentTransaction.status);
  }

  function TransactionHasNotReachedSuccessfulState() {
    return ![PAYMENT_STATUS_COMPLETED, PAYMENT_STATUS_REFUNDED].includes(currentTransaction.status);
  }

  function TabAmountIsAboveHeldAmount() {
    return receiptItems.balance > receiptItems.amountHeld;
  }

  return (
    <>
      <div className="row">
        <div className="buttons col-xs-12 col-sm-12 col-md-8 pull-right">
          <div className="row mr-minus-10">
            <div className="col-xs-6 buttons__button__container col-sm-3">
              {isTabSelected === true && isTransactionSelected === false &&
                <button
                  disabled={ReceiptHasNoItems() || TabIsNotPreAuthorized() || ReceiptItemIsSelected()}
                  className={`buttons__button button__clear ${ReceiptHasNoItems() || TabIsNotPreAuthorized() || ReceiptItemIsSelected() ? "disabled-button" : ""}`}
                  onClick={() => clearTabItems()}
                  type="button">
                  Clear
                </button>
              }
              {isTabSelected === false && isTransactionSelected === true &&
                <button
                  disabled={true}
                  className={`buttons__button button__clear disabled-button`}
                  type="button">
                  Clear
                </button>
              }
              {isTabSelected === false && isTransactionSelected === false &&
                <button
                  disabled={ReceiptHasNoItems() || ReceiptItemIsSelected()}
                  className={`buttons__button button__clear ${ReceiptHasNoItems() || ReceiptItemIsSelected() ? "disabled-button" : ""}`}
                  onClick={() => clearPOSItems()}
                  type="button">
                  Clear
                </button>
              }
            </div>
            <div className="col-xs-6 buttons__button__container col-sm-3">
              {isTabSelected === true && isTransactionSelected === false &&
                <button className={`buttons__button button__reports ${ReceiptItemIsSelected() || ReceiptHasNoItems() || TabAmountIsHeldAmount() || TabIsNotPreAuthorized() ? "disabled-button" : ""}`}
                  disabled={ReceiptItemIsSelected() || ReceiptHasNoItems() || TabAmountIsHeldAmount() || TabIsNotPreAuthorized()}
                  onClick={() => updatePayment()}
                  type="button">
                  Amend Tab
                </button>
              }
              {isTabSelected === false && isTransactionSelected === true &&
                <button className={`buttons__button button__reports ${TransactionHasNotReachedSuccessfulState() ? "disabled-button" : ""}`}
                  disabled={TransactionHasNotReachedSuccessfulState()}
                  onClick={() => showReceipt()}
                  type="button"
                >
                  {receiptItems.isVisible === true && <>Show Receipt</>}
                  {receiptItems.isVisible === false && <>Show Items</>}
                </button>
              }
              {isTabSelected === false && isTransactionSelected === false &&
                <button className={`buttons__button button__reports ${ReceiptItemIsSelected() || ReceiptHasNoItems() ? "disabled-button" : ""}`}
                  disabled={ReceiptItemIsSelected() || ReceiptHasNoItems()}
                  type="button"
                  onClick={() => addToTab()}
                >
                  Add to Tab
                </button>
              }
            </div>
            <div className="col-xs-6 buttons__button__container col-sm-3">
              {isTabSelected === true && isTransactionSelected === false &&
                <>
                  {currentItemIndex === -1 &&
                    <button className={`buttons__button button__cancel-transaction ${ReceiptItemIsSelected() || BothReceiptOrTabIsNotSeleceted() || TabHasReachedTerminalStatus() ? "disabled-button" : ""}`}
                      disabled={ReceiptItemIsSelected() || BothReceiptOrTabIsNotSeleceted() || TabHasReachedTerminalStatus()}
                      onClick={() => reversePayment()}
                      type="button">
                      Cancel Tab
                    </button>
                  }
                  {currentItemIndex !== -1 &&
                    <button
                      disabled={receiptItems.items.length === 0}
                      className={`buttons__button button__remove ${ReceiptHasNoItems() ? "disabled-button" : ""}`}
                      onClick={() => removeItem()}
                      type="button">
                      Remove Item
                    </button>
                  }
                </>
              }
              {isTabSelected === false && isTransactionSelected === true &&
                <button className={`buttons__button button__cancel-transaction ${BothReceiptOrTabIsNotSeleceted() || TransactionHasReachedTerminalStatus() ? "disabled-button" : ""}`}
                  disabled={BothReceiptOrTabIsNotSeleceted() || TransactionHasReachedTerminalStatus()}
                  onClick={() => cancelPayment()}
                  type="button">
                  Cancel Transaction
                </button>

              }
              {isTabSelected === false && isTransactionSelected === false &&
                <>
                  {currentItemIndex === -1 &&
                    <button className={`buttons__button button__cancel-transaction disabled-button`}
                      disabled={true}
                      type="button">
                      Cancel Transaction
                    </button>
                  }
                  {currentItemIndex !== -1 &&
                    <button
                      disabled={receiptItems.items.length === 0}
                      className={`buttons__button button__remove ${ReceiptHasNoItems() ? "disabled-button" : ""}`}
                      onClick={() => removeItem()}
                      type="button">
                      Remove Item
                    </button>
                  }
                </>
              }
            </div>
            <div className="col-xs-6 buttons__button__container col-sm-3">
              {isTabSelected === true && isTransactionSelected === false &&
                <button className={`buttons__button button__card-payment ${ReceiptItemIsSelected() || ReceiptHasNoItems() || TabIsNotPreAuthorized() || TabAmountIsAboveHeldAmount() ? "disabled-button" : ""}`}
                  disabled={ReceiptItemIsSelected() || ReceiptHasNoItems() || TabIsNotPreAuthorized() || TabAmountIsAboveHeldAmount()}
                  onClick={() => capturePayment()}
                  type="button">
                  Capture Payment
                </button>
              }
              {isTabSelected === false && isTransactionSelected === true &&
                <button className={`buttons__button button__card-payment ${TransactionHasNotReachedRefundableState() ? "disabled-button" : ""}`}
                  disabled={TransactionHasNotReachedRefundableState()}
                  type="button"
                  onClick={() => refundPayment()}>
                  Refund
                </button>
              }
              {isTabSelected === false && isTransactionSelected === false &&
                <button className={`buttons__button button__card-payment ${ReceiptItemIsSelected() || ReceiptHasNoItems() ? "disabled-button" : ""}`}
                  disabled={ReceiptItemIsSelected() || ReceiptHasNoItems()}
                  onClick={() => cardPayment()}
                  type="button">
                  Card Payment
                </button>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export { PosButtons };