import React from 'react';
import { WS_CONNECTION_STATUS_CLOSED, WS_CONNECTION_STATUS_CONNECTED, WS_CONNECTION_STATUS_DISCONNECTED, WS_CONNECTION_STATUS_FAILED } from '../../store/state';

function ConnectionError(props) {

  const {wsConnectionStatus, connectionError} = props;

  return (
    <>
      {wsConnectionStatus !== WS_CONNECTION_STATUS_CONNECTED &&
        <div id="errorContainer" className="container error-container" style={{ display: 'block' }}>
          <div className="alert alert-danger fade in error-alert">
            <div className="close" data-dismiss="alert" aria-label="close" title="close">×</div>
            {wsConnectionStatus === WS_CONNECTION_STATUS_DISCONNECTED &&
              <div id="errorText">{connectionError}</div>
            }
            {wsConnectionStatus === WS_CONNECTION_STATUS_FAILED &&
              <div id="errorText">
                <b>This EPoS cannot communicate with the PAC API.</b><br />
                Please ensure that the <b>accountName</b> and <b>apiKey</b> in the URL are correct. If the problem persists, please contact support.
              </div>
            }
            {wsConnectionStatus === WS_CONNECTION_STATUS_CLOSED &&
              <div id="errorText">
                <b>This EPoS cannot communicate with the PAC API.</b><br />
                If the problem persists, please contact support.
              </div>
            }
          </div>
        </div>
      }
    </>
  );
}

export { ConnectionError }