import React from 'react';
import { PAYMENT_STATUS_PRE_AUTHORIZED } from '../../websocket/pacApi';

function PosItemRow(props) {

  const { posItems, modals, items, receiptItems, setReceiptItems } = props;

  const posItemRow = items.map((posItemButton) =>
    <PosItemTile
      key={posItemButton.key}
      name={posItemButton.name}
      price={posItemButton.price}
      id={posItemButton.key}
      posItems={posItems}
      modals={modals}
      receiptItems={receiptItems}
      setReceiptItems={setReceiptItems}
    />
  )

  return (
    <>
      <div className="row mr-minus-10">
        {posItemRow}
      </div>
    </>
  );
}

function PosItemTile(props) {

  const { id, name, price, posItems, modals, receiptItems, setReceiptItems } = props;

  function addPosReceiptItem() {
    const check = modals.tabsView.isTabSelected === true ? (PosItemsDisabled() || TabIsNotPreAuthorized()) : PosItemsDisabled();
    if (!check) {
      let existingItem = false;
      receiptItems.items.forEach((item, index) => {
        if (item.name === name) {
          receiptItems.items[index].quantity = receiptItems.items[index].quantity + 1;
          existingItem = true;
        }
      });
      if (!existingItem) {
        receiptItems.items.push({
          name: name,
          quantity: 1,
          price: price,
          key: id,
        })
      }
      receiptItems.balance += price;
      setReceiptItems({ ...receiptItems });
    }
  }

  function PosItemsDisabled() {
    return posItems.isActive === false || receiptItems.currentItem.index !== -1;
  }

  function TabIsNotPreAuthorized() {
    return modals.tabsView.tabs[receiptItems.currentSelectedTab.index].status !== PAYMENT_STATUS_PRE_AUTHORIZED;
  }

  return (
    <>
      {modals.transactionsView.isTransactionSelected === false &&
        <div className="col-xs-4 products__product" onClick={() => addPosReceiptItem()}>
          {modals.tabsView.isTabSelected === true && 
            <div className={`product-click-container ${PosItemsDisabled() || TabIsNotPreAuthorized() ? "disabled-button" : ""}`}>
              <div className={`products__product__image-container ${name}`}></div>
              <div className="products__product__text text-center">
                {name.charAt(0).toUpperCase() + name.substring(1)}
              </div>
            </div>
          }
          {modals.tabsView.isTabSelected === false &&
            <div className={`product-click-container ${PosItemsDisabled() ? "disabled-button" : ""}`}>
              <div className={`products__product__image-container ${name}`}></div>
              <div className="products__product__text text-center">
                {name.charAt(0).toUpperCase() + name.substring(1)}
              </div>
            </div>
          }
        </div>
      }
      {modals.tabsView.isTabSelected === false && modals.transactionsView.isTransactionSelected === true &&
        <div className="col-xs-4 products__product">
          <div className={`product-click-container disabled-button`}>
            <div className={`products__product__image-container ${name}`}></div>
            <div className="products__product__text text-center">
              {name.charAt(0).toUpperCase() + name.substring(1)}
            </div>
          </div>
        </div>
      }
    </>
  );
}

export { PosItemRow };