import React from 'react';
import CurrencyFormat from 'react-currency-format';
import { PosReceiptItem } from './PosReceiptItem';
import { FORMAT_BOLD, FORMAT_DOUBLE_HEIGHT, FORMAT_DOUBLE_WIDTH } from '../../websocket/pacApi';

function PosReceipt(props) {

  const { modals, receiptItems, setReceiptItems, clearPreviouslySelectedItems } = props;

  const receiptItemsList = receiptItems.items.map((item, key) =>
    <PosReceiptItem
      key={key}
      index={key}
      name={item.name}
      quantity={item.quantity}
      price={item.price}
      modals={modals}
      receiptItems={receiptItems}
      setReceiptItems={setReceiptItems}
      clearPreviouslySelectedItems={clearPreviouslySelectedItems}
    />
  );

  const formattedCustomerReceipt = receiptItems.receiptLines?.CUSTOMER?.map((receiptLine, key) => formatLines(receiptLine, key));
  const seperator = [{value: ""}, {value: ""}, {value: "==================="}, {value: ""}].map((receiptLine, key) => formatLines(receiptLine, key));
  const formattedMerchantReceipt = receiptItems.receiptLines?.MERCHANT?.map((receiptLine, key) => formatLines(receiptLine, key));

  function formatLines(receiptLine, key) {
    return (
    <div key={key} className="receipt__selected-product__container">
      {receiptLine.value !== "" &&
        <div className={
          "receipt__selected-product-name pull-left"
          + `${receiptLine.format?.includes(FORMAT_BOLD) ? " format-bold" : ""}`
          + `${receiptLine.format?.includes(FORMAT_DOUBLE_HEIGHT) ? " format-double-height" : ""}`
          + `${receiptLine.format?.includes(FORMAT_DOUBLE_WIDTH) ? " format-double-width" : ""}`
        }>
          {receiptLine.value}
        </div>
      }
    </div>);
  }

  return (
    <div className="col-xs-12 col-sm-5 col-md-5">
      <div className="row">
        <div className="receipt-before-zigzag">

        </div>
        {receiptItems.isVisible === true &&
          < div id="receipt" className="receipt">
            {receiptItemsList}
          </div>
        }
        {receiptItems.isVisible === false &&
          <div id="receipt" className="receipt">
            {formattedCustomerReceipt}
            {receiptItems.receiptLines?.CUSTOMER && seperator}
            {formattedMerchantReceipt}
          </div>
        }
        <div className="receipt-after-zigzag">

        </div>
        <div className="payments">
          <span className="payments__text">Balance:</span>
          <span className="payments__amount">
            <CurrencyFormat value={parseFloat(receiptItems.balance / 100)} prefix={'£'} displayType={'text'} fixedDecimalScale={true} decimalScale={2} />
          </span>
        </div>
        {receiptItems.currentSelectedTab.index !== -1 &&
          <div className="payments">
            <span className="payments__text">Amount Held:</span>
            <span className="payments__amount">
              <CurrencyFormat value={parseFloat(receiptItems.amountHeld / 100)} prefix={'£'} displayType={'text'} fixedDecimalScale={true} decimalScale={2} />
            </span>
          </div>
        }
        {receiptItems.currentSelectedTab.index !== -1 &&
          <div className="payments">
            <span className="payments__text">Amount Captured:</span>
            <span className="payments__amount">
              <CurrencyFormat value={parseFloat(receiptItems.amountCaptured / 100)} prefix={'£'} displayType={'text'} fixedDecimalScale={true} decimalScale={2} />
            </span>
          </div>
        }
      </div>
    </div >
  );
}

export { PosReceipt };